import { getClientIp } from 'request-ip';
import type { GetServerSidePropsContext, NextPageContext } from 'next';
import type { ParsedUrlQuery } from 'querystring';
import type SplitIO from '@splitsoftware/splitio/types/splitio';

import adapter from './adapter';
import { parseContextI18n } from '@yoweb/next-i18next/createPageResponse';
import type { SplitKeyGenerator, Split, IClient } from './adapter';

const DEFAULT_TREATMENT_VALUE = 'on';

export type WithFeatureContext = GetServerSidePropsContext<ParsedUrlQuery> | NextPageContext;

export type SplitQuery = {
  name: string;
  treatment?: SplitIO.Treatment;
  includeLocaleAttr?: boolean;
  includeIpAttr?: boolean;
  noUniqueKeys?: boolean; // If true, this split is being used as a gate, not an experiment. Don't need a unique key per user so set it to the split name to save MTKs.
  attributes?: (context: WithFeatureContext) => Promise<SplitIO.Attributes> | SplitIO.Attributes;
};

export type WithFeatureOptions = {
  /**
   * Provides the treatment(s) as a page prop and does not redirect if treatment is disabled
   */
  passThrough?: boolean;
  client: IClient;
  generateKey: SplitKeyGenerator;
};

export type WithFeatureSplit = string | SplitQuery | (SplitQuery | string)[];

export type FeatureNotFound = {
  notFound: boolean;
};

export type FeatureTreatments = {
  props: {
    treatments: SplitIO.Treatments;
  };
};

export type FeatureTreatmentsWithFeatureOffHint = FeatureTreatments & {
  oneOrMoreTreatmentsAreOff: boolean;
};

export function isFeatureTreatments(
  value: FeatureTreatments | FeatureNotFound | object,
): value is FeatureTreatments {
  return 'props' in value;
}

const assertIsSplitQuery = (value: unknown): value is SplitQuery =>
  !!(value && typeof value === 'object' && 'name' in value);

const transformQueries = async (queries: SplitQuery[], context: WithFeatureContext) => {
  if (!context.req || !context.res) {
    throw new Error('transformQueries was executed outside the context of the server, unexpected.');
  }

  const splitQueries: (Promise<Split> | Split)[] = [];

  for (const query of queries) {
    const splitName = query.name;
    let attributes: SplitIO.Attributes | undefined;

    if (typeof query.includeLocaleAttr === 'undefined' || query.includeLocaleAttr) {
      const { lcaseLocale } = parseContextI18n(context);

      attributes = {
        ...attributes,
        locale: lcaseLocale,
      };
    }

    if (typeof query.includeIpAttr === 'undefined' || query.includeIpAttr) {
      attributes = {
        ...attributes,
        ip: getClientIp(context.req) ?? '',
      };
    }

    let splitObject: Split = {
      splitName,
      attributes,
    };

    if (query.noUniqueKeys) {
      splitObject = {
        ...splitObject,
        key: splitName,
      };
    }

    if (query.attributes) {
      splitQueries.push(
        Promise.resolve(query.attributes(context)).then((settledAttributes) => ({
          ...splitObject,
          attributes: {
            // Merge user attrs with possible attrs we computed
            ...splitObject.attributes,
            ...settledAttributes,
          },
        })),
      );
    } else {
      splitQueries.push(splitObject);
    }
  }

  return Promise.all(splitQueries);
};

export const normalizeSplitInput = (input: WithFeatureSplit): SplitQuery[] => {
  const splits = Array.isArray(input) ? input : [input];

  return splits.map((split) => (assertIsSplitQuery(split) ? split : { name: split }));
};

export function calculateResponse(
  options: WithFeatureOptions,
  rawResponse: FeatureTreatmentsWithFeatureOffHint,
): FeatureTreatments | FeatureNotFound | object {
  if (rawResponse.oneOrMoreTreatmentsAreOff && options.passThrough !== true) {
    return { notFound: true };
  } else if (options.passThrough === true) {
    return { props: rawResponse.props };
  }

  return {};
}

export const withFeature =
  (split: WithFeatureSplit, options: WithFeatureOptions) => async (context: WithFeatureContext) =>
    calculateResponse(options, await withFeatureReturnTreatments(split, options)(context));

export const withFeatureReturnTreatments =
  (split: WithFeatureSplit, options: WithFeatureOptions) =>
  async (context: WithFeatureContext): Promise<FeatureTreatmentsWithFeatureOffHint> => {
    if (!context.req || !context.res) {
      throw new Error(
        'withFeatureReturnTreatments was executed outside the context of the server, unexpected.',
      );
    }

    const queries = normalizeSplitInput(split);
    const key = adapter.getKey(context.req, context.res, options.generateKey);
    const splitObjects = await transformQueries(queries, context);
    const treatmentsResponse = await adapter.getTreatments(key, splitObjects, options.client);
    return {
      props: {
        treatments: treatmentsResponse,
      },
      oneOrMoreTreatmentsAreOff: isAtLeastOneFeatureOff(treatmentsResponse, queries, splitObjects),
    };
  };

function isAtLeastOneFeatureOff(
  treatments: SplitIO.Treatments,
  queries: SplitQuery[],
  splitObjects: Split[],
) {
  const responseKeys = Object.keys(treatments);
  const hasTreatments = splitObjects.every(({ splitName }) => responseKeys.includes(splitName));

  if (hasTreatments) {
    for (const query of queries) {
      const expected = query.treatment ?? DEFAULT_TREATMENT_VALUE;

      if (treatments?.[query.name] !== expected) {
        return true;
      }
    }
  }
  return false;
}
